function StudentAverage(props) {
    this.firstName = props.firstName;
    this.lastName = props.lastName;
    this.studentId = props.studentId;
    this.extStudentId = props.extStudentId;
    this.googleUserId = props.googleUserId || '0';
    this.emailAddress = props.googleEmail || '';
    // this.googleName = '';
    this.studentEnrollmentId = props.studentEnrollmentId;
    this.gradeLevel = props.gradeLevel;
    this.homeRoom = props.homeRoom;
    // this.googleAccountId = props.googleAccountId;
    this.courseSectionId = props.courseSectionId;
    this.courseSectionStudentId = props.courseSectionStudentId;
    this.markingPeriods = [];
}

export default StudentAverage;
