import async from 'async';
import moment from 'moment';
import * as network from '../network';

import { store } from '../store';
import Types from '../store/Types';
import { getSessionUser } from '../store/mixins/userMixins';
import { getCourses, CourseFilter } from '../store/mixins/courseMixins';

const logCategory = 'portfolioTotals';
const slowCalcIndicator = 500; // ms

// the worker lives in /public
const portfolioTotalsWorker = new Worker('/portfolioTotals-worker.js');
portfolioTotalsWorker.onmessage = function (e) {
    const {
        user, teacher, cache, totals, teachers, courseSectionTeachers, markingPeriods,
    } = e.data;
    cache.status = 'cached';
    cache.timestamp = moment().format('YYYY-MM-DD HH:mm:ss');
    store.commit(Types.mutations.UPSERT_ITEM_TO_CACHE, cache);

    // @ts-ignore
    const { schoolTermId, schoolId, role } = user.school;
    const { lastName, firstName, schoolStaffId } = teacher;
    const saveToServer = !(['Guardian', 'Student'].includes(role));

    window.syncGrades.log(`Portfolio Totals received from worker for ${lastName}, ${firstName}`, 'info', logCategory);

    const courseSectionTeachersUpsert = courseSectionTeachers.filter((cst) => cst.schoolStaffId == schoolStaffId).map((cst) => {
        const courseSectionTeacher = { ...cst };
        courseSectionTeacher.totals = totals.filter((t) => t.courseSectionId == cst.courseSectionId && t.schoolStaffId == schoolStaffId) || [];
        return courseSectionTeacher;
    });
    store.commit(Types.mutations.SET_DB_COURSE_SECTION_TEACHERS, courseSectionTeachersUpsert);

    const teachersUpsert = teachers.map((row) => {
        const t = { ...row };
        if (t.schoolStaffId == schoolStaffId) {
            t.totals = markingPeriods.filter((mp) => !mp.deleted).map((mp) => {
                const { schoolTermMarkingPeriodId } = mp;
                const markingPeriodTotals = totals.filter((tot) => tot.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId);

                if (!markingPeriodTotals.length) return null;

                return {
                    schoolTermMarkingPeriodId,
                    totalAssignments: markingPeriodTotals.reduce((acc, cur) => parseFloat(acc) + parseFloat(cur.totalAssignments), 0),
                    categorizedAssignments: markingPeriodTotals.reduce((acc, cur) => parseFloat(acc) + parseFloat(cur.categorizedAssignments), 0),
                    uncategorizedAssignments: markingPeriodTotals.reduce((acc, cur) => parseFloat(acc) + parseFloat(cur.uncategorizedAssignments), 0),
                    totalSectionStudents: markingPeriodTotals.reduce((acc, cur) => parseFloat(acc) + parseFloat(cur.totalSectionStudents), 0),
                    completedReportCards: markingPeriodTotals.reduce((acc, cur) => parseFloat(acc) + parseFloat(cur.completedReportCards), 0),
                };
            }).filter((mp) => mp);
        }
        return t;
    });

    store.commit(Types.mutations.SET_DB_TEACHERS, teachersUpsert);

    window.syncGrades.log('Portfolio Totals updated locally in state', 'info', logCategory);

    window.performance.mark(`endPortfolioTotalsCalc_${lastName}, ${firstName}`);
    window.performance.measure('portfolioTotalsCalc', `startPortfolioTotalsCalc_${lastName}, ${firstName}`, `endPortfolioTotalsCalc_${lastName}, ${firstName}`);
    const [item] = performance.getEntriesByName('portfolioTotalsCalc');
    if (item) window.syncGrades.log(`${lastName}, ${firstName} totals calculation took: ${item.duration.toFixed(2)}ms`, item.duration > slowCalcIndicator ? 'warn' : 'info', logCategory);

    if (!totals || totals.length == 0 || !saveToServer) return window.syncGrades.log('No totals to save to remote server', 'info', logCategory);

    const params = {
        url: { schoolTermId, schoolId, schoolStaffId },
        body: { courseSectionTeacherTotals: totals },
    };

    network.courseSectionsTeachers.upsertCourseSectionTotals(params, (errSave) => {
        if (errSave) return window.syncGrades.log(errSave, 'error', logCategory);
        window.syncGrades.log('Portfolio Totals saved to remote server', 'info', logCategory);
    });
};

export function populateTeacherTotals($store, teacher) {
    const { commit, state } = $store;
    const { user } = state;
    if (!teacher) return;

    const { schoolStaffId, lastName, firstName } = teacher;
    const { database } = state;

    const key = `staffPortfolioTotals_${schoolStaffId}`;
    const cache = {
        key,
        cacheTitle: `Portfolio Totals ${lastName}, ${firstName}`,
        cacheType: 'portfolioTotals',
        status: 'loading',
        timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
    };

    window.syncGrades.log(`Saturating ${cache.cacheType} portfolio ${cache.cacheTitle}`, 'info', logCategory);
    window.performance.mark(`startPortfolioTotalsCalc_${lastName}, ${firstName}`);
    const session = getSessionUser(state);
    const courses = getCourses(database, new CourseFilter({ schoolStaffId, session })).filter((c) => !c.deleted);
    const {
        teachers, courseSectionTeachers, courseSectionStudents, courseWork, googleCourseWork, markingPeriods, studentMarkingPeriodGrades,
    } = database;

    const payload = {
        user,
        teacher,
        cache,
        courses,
        teachers,
        courseSectionTeachers,
        courseSectionStudents,
        courseWork,
        googleCourseWork,
        markingPeriods,
        studentMarkingPeriodGrades,
        totals: [],
    };

    commit(Types.mutations.UPSERT_ITEM_TO_CACHE, cache);
    window.syncGrades.log(`Message sent to worker for processing of ${lastName}, ${firstName} totals`, 'info', logCategory);
    // send payload to the worker for processing
    async.auto({
        worker(next) {
            portfolioTotalsWorker.postMessage(payload);
            next();
        },
    }, (err) => {
        if (err) return window.syncGrades.log(err, 'Worker error', logCategory);
        window.syncGrades.log(`Portfolio Totals request sent to worker for ${lastName}, ${firstName}`, 'info', logCategory);
    });
}
