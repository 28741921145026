import async from 'async';
import moment from 'moment';
import * as averagingModels from './models';

import * as network from '../../network';
import Types from '../../store/Types';
import { store } from '../../store';
import { getCourses, CourseFilter } from '../../store/mixins/courseMixins';
import { getStudents, StudentFilter } from '../../store/mixins/studentMixins';
import { getCourseWork } from '../../store/mixins/courseWorkMixins';
import { getCourseWorkGrades } from '../../store/mixins/courseWorkGradeMixins';
// import * as util from './utils';

// logging, perf tools
const logCategory = 'calculate';
const slowCalcIndicator = 500; // ms

// the worker lives in /public
const calculationWorker = new Worker('/calculation-worker.js', { type: 'module' });
calculationWorker.onmessage = function (e) {
    const { extCourseSectionId, cache, students } = e.data;
    cache.status = 'cached';
    cache.timestamp = moment().format('YYYY-MM-DD HH:mm:ss');
    store.commit(Types.mutations.UPSERT_ITEM_TO_CACHE, cache);

    const markingPeriodStudentAverages = [];
    students.forEach((studentCalc) => {
        studentCalc.markingPeriods.forEach((markingPeriod) => {
            const averageCalculation = { ...studentCalc };
            delete averageCalculation.markingPeriods;
            averageCalculation.markingPeriod = markingPeriod;

            markingPeriodStudentAverages.push({
                studentEnrollmentId: studentCalc.studentEnrollmentId,
                courseSectionId: studentCalc.courseSectionId,
                courseSectionStudentId: studentCalc.courseSectionStudentId,
                schoolTermId: markingPeriod.schoolTermId,
                schoolTermMarkingPeriodId: markingPeriod.schoolTermMarkingPeriodId,
                scaledMark: markingPeriod.scaled ? markingPeriod.scaled.mark : null,
                markingPeriodAverage: markingPeriod.average,
                averageCalculation,
            });
        });
    });

    window.syncGrades.log(`Calculations received from worker for ${extCourseSectionId}, with ${markingPeriodStudentAverages.length} students`, 'info', logCategory);
    // @ts-ignore
    const { user, route } = store.state;
    const { schoolTermId, schoolId, schoolStaffId } = user.school;

    const saveToServer = !(['Guardian', 'Student'].includes(user.school.role));
    store.commit(Types.mutations.SET_DB_MARKING_PERIOD_STUDENT_AVERAGES, markingPeriodStudentAverages);

    // update the updatedAverages list so the gradebook knows to update
    if (route.name === 'TeacherCourseGradebook') {
        markingPeriodStudentAverages.forEach((m) => {
            const { studentEnrollmentId, courseSectionId, schoolTermMarkingPeriodId } = m;
            const key = `average_${studentEnrollmentId}_${courseSectionId}_${schoolTermMarkingPeriodId}`;
            store.commit(Types.mutations.SET_GRADEBOOK_UPDATED_AVERAGE, key);
        });
    }

    window.syncGrades.log('Calculations updated locally in state', 'info', logCategory);

    window.performance.mark(`endAverageCalc_${extCourseSectionId}`);
    window.performance.measure('averageCalc', `startAverageCalc_${extCourseSectionId}`, `endAverageCalc_${extCourseSectionId}`);
    const [item] = performance.getEntriesByName('averageCalc');
    if (item) window.syncGrades.log(`${extCourseSectionId} calculation took: ${item.duration.toFixed(2)}ms`, item.duration > slowCalcIndicator ? 'warn' : 'info', logCategory);

    if (saveToServer) {
        const params = {
            url: { schoolTermId, schoolId, schoolStaffId },
            body: { markingPeriodStudentAverages },
        };
        network.averages.saveByStaff(params, (errSave) => {
            if (errSave) return window.syncGrades.log(errSave, 'error', logCategory);
            window.syncGrades.log('Calculations saved to remote server', 'info', logCategory);
        });
    }
};

function calculateAverageByExtCourseSectionId($store, extCourseSectionId) {
    const { state } = $store;
    const { database } = state;

    // create a list of courses including the combos
    const courses = getCourses(database, new CourseFilter({ extCourseSectionId }));
    const [firstCourse] = courses;
    if (!firstCourse) return window.syncGrades.log('Could not find course extCourseSectionId', 'error', logCategory);

    async.eachOfLimit(courses, 1, (c, idx, nextCourse) => {
        const course = { ...c };
        course.gradeTemplate = firstCourse.gradeTemplate;
        // make sure not to pass combos since they're already split
        if (course.comboCourses) course.comboCourses = [];
        window.syncGrades.log(`Calculating averages ByExtCourseSectionId ${course.extCourseSectionId}`, 'info', logCategory);
        calculateAverageSingleCourse($store, course, null);
        nextCourse();
    }, () => { });
}

function calculateAverageByCourseSectionId($store, courseSectionId, groupCombos = true) {
    const { state } = $store;
    const { database } = state;

    // create a list of courses including the combos
    const courses = getCourses(database, new CourseFilter({ courseSectionId }));
    const [firstCourse] = courses;
    if (!firstCourse) return window.syncGrades.log('Could not find course courseSectionId', 'error', logCategory);

    if (groupCombos) {
        courses.forEach((c) => {
            const course = { ...c };
            course.gradeTemplate = firstCourse.gradeTemplate;
            // make sure not to pass combos since they're already split
            if (course.comboCourses) course.comboCourses = [];
            calculateAverageSingleCourse($store, course, null);
        });
    } else {
        firstCourse.comboCourses = [];
        calculateAverageSingleCourse($store, firstCourse, null);
    }
}

function calculateAverageForStudent($store, studentEnrollmentId) {
    const { database } = $store.state;
    const courses = getCourses(database, new CourseFilter({ studentEnrollmentId }));
    async.eachOfLimit(courses, 5, (c, idx, nextCourse) => {
        const course = { ...c };
        // make sure not to pass combos since they're already split
        if (course.comboCourses) course.comboCourses = [];
        calculateAverageSingleCourse($store, course, studentEnrollmentId);
        nextCourse();
    }, () => { });
}

function calculateAverageForStudentInCourse($store, courseSectionId, studentEnrollmentId) {
    const { database } = $store.state;
    const courses = getCourses(database, new CourseFilter({ courseSectionId }));
    const [course] = courses;
    course.comboCourses = [];
    calculateAverageSingleCourse($store, course, studentEnrollmentId);
}

function calculateAverageSingleCourse($store, course, studentEnrollmentId) {
    const { state } = $store;
    const { database, user } = state;
    const { schoolTermMarkingPeriodId } = database;
    const { courseSectionId, gradeTemplate, extCourseSectionId } = course;

    window.performance.mark(`startAverageCalc_${extCourseSectionId}`);
    window.syncGrades.log(`Calculating averages for ${extCourseSectionId}`, 'info', logCategory);
    const markingPeriods = database.markingPeriods
        .map((m) => new averagingModels.MarkingPeriod({ ...m }, user.school));
    if (markingPeriods.length == 0) return window.syncGrades.log('There are no markingPeriods for averaging', 'warn', logCategory);

    const students = getStudents(database, new StudentFilter({ courseSectionId }))
            .filter((m) => m.courseSectionId == courseSectionId)
            .filter((m) => {
                if (!studentEnrollmentId) return true;
                return m.studentEnrollmentId == studentEnrollmentId;
             })
            .map((m) => new averagingModels.StudentAverage({ ...m }));

    if (students.length == 0) return window.syncGrades.log('There are no students for averaging', 'warn', logCategory);
    const assignments = getCourseWork(database, course).filter((a) => !a.deleted);
    if (!gradeTemplate) return window.syncGrades.log('Could not find template for averaging', 'warn', logCategory);

    const allGrades = getCourseWorkGrades(database, course).filter((g) => !g.deleted);
    const key = !studentEnrollmentId ? `portfolioCalc_${extCourseSectionId}_${schoolTermMarkingPeriodId}`
    : `portfolioCalc_${studentEnrollmentId}_${extCourseSectionId}_${schoolTermMarkingPeriodId}`;

    const cache = {
        key,
        cacheTitle: `Portfolio Calculation ${extCourseSectionId}`,
        cacheType: 'portfolioCalc',
        status: 'loading',
        timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
    };

    const payload = {
        extCourseSectionId,
        students,
        cache,
        schoolTermMarkingPeriodId,
        markingPeriods,
        gradeTemplate,
        assignments,
        allGrades,
    };
    $store.commit(Types.mutations.UPSERT_ITEM_TO_CACHE, cache);
    window.syncGrades.log(`Message sent to worker for processing of ${payload.extCourseSectionId}, ${payload.students.length} students`, 'info', logCategory);
    // send payload to the worker for processing
    calculationWorker.postMessage(payload);
}

export {
    calculateAverageByExtCourseSectionId as default,
    calculateAverageByCourseSectionId,
    calculateAverageByExtCourseSectionId,
    calculateAverageForStudent,
    calculateAverageForStudentInCourse,
};
