/*
function Course(props) {
    this.courseName = props.courseName;
    this.courseSectionId = props.courseSectionId;
    this.extCourseSectionId = props.extCourseSectionId;
    this.schoolTermId = props.schoolTermId;
    this.googleCourses = []; //not sure about this
    this.averages = [];
}

export default Course;
*/
