import Types from '../Types';
import { calculateAverageByExtCourseSectionId } from '../../lib/average/calculate';
import { populateTeacherTotals } from '../../lib/portfolioTotals';

let debounce = null;

const portfolioMixins = {
    beforeRouteUpdate(to, from, next) {
        if (to.name == from.name && JSON.stringify(to.params) == JSON.stringify(from.params)) {
            // in page navigation, dont recalculate
            return next();
        }
        debounced(to, this.$store, this);
        next();
    },
    mounted() {
        debounced(this.$route, this.$store, this);
    },
    watch: {
        $_portfolioMixins_schoolTermMarkingPeriodId() {
            // debounced(this.$route, this.$store, this);
            const { path } = this.$route;
            const { extCourseSectionId, studentEnrollmentId } = this.$route.params;
            if (studentEnrollmentId || (path.endsWith('/info') && !extCourseSectionId)) return; // On student portfolio or teacher info page don't calc
            calculateAverageByExtCourseSectionId(this.$store, extCourseSectionId);
        },
        databaseIsReady() {
            setTimeout(() => {
                debounced(this.$route, this.$store, this);
            }, 10);
        },
    },
    computed: {
        databaseIsReady() {
            return Boolean(this.$store.state.database.loadState === 'READY');
        },
        $_portfolioMixins_getPortfolioCacheKey() {
            return getPortfolioCacheKey(this.$route, this.$store.state.database);
        },
        isPortfolioReady() {
            const cacheKey = getPortfolioCacheKey(this.$route, this.$store.state.database);
            return Boolean(this.$store.state.database.cache.find((c) => c.key == cacheKey && c.status == 'cached'));
        },
        $_portfolioMixins_schoolTermMarkingPeriodId() {
            return this.$store.state.settings.schoolTermMarkingPeriodId;
        },
    },
};

function debounced(route, store, v) {
    clearTimeout(debounce);
    debounce = setTimeout(() => {
        populate(route, store, v);
    }, 250);
}

function populate(route, store, v) {
    const { showError, databaseIsReady } = v;
    if (!databaseIsReady) return;
    const extCourseSectionId = route.params.extCourseSectionId || null;
    const schoolEmail = route.params.schoolEmail || null;
    const studentEnrollmentId = route.params.studentEnrollmentId || route.query.studentEnrollmentId || null;
    const { schoolTermMarkingPeriodId } = store.state.settings;

    const isTeacherRoute = (route.fullPath.includes('/teachers') && schoolEmail) || ((route.fullPath.includes('/classes/') && extCourseSectionId) && !route.fullPath.includes('/students/'));
    const isStudentRoute = route.fullPath.includes('/students') && studentEnrollmentId;
    const userName = store.state.user.mappedUserName || store.state.user.userName;

    if (isTeacherRoute) {
        // remember the recent portfolio in the left nav menu, if not self
        if (schoolEmail != userName) {
            store.commit(Types.mutations.ADD_PORTFOLIO_HISTORY, {
                type: 'Teacher',
                schoolEmail,
            });
        }
    }

    if (isStudentRoute) {
        // remember the recent portfolio in the left nav menu
        store.commit(Types.mutations.ADD_PORTFOLIO_HISTORY, {
            type: 'Student',
            studentEnrollmentId,
        });
    }

    // updates the portfolio data if it doesnt exits or is stale within the local db
    store.dispatch(Types.actions.POPULATE_PORTFOLIO, {
        schoolEmail,
        studentEnrollmentId,
        callback(err) {
            if (err) return showError(err);

            // trigger quickPanel showing on load
            if (route.query && route.query.panel) {
                store.commit(Types.mutations.OPEN_QUICK_PANEL);
            } else {
                store.commit(Types.mutations.CLOSE_QUICK_PANEL);
            }

            // new averages
            if (schoolEmail && extCourseSectionId) {
                const key = `portfolioCalc_${extCourseSectionId}_${schoolTermMarkingPeriodId}`;
                const cached = Boolean(store.state.database.cache.find((c) => c.key == key && (c.status == 'loading' || c.status == 'cached')));
                if (!cached) {
                    calculateAverageByExtCourseSectionId(store, extCourseSectionId);
                }
            }

            if (schoolEmail) {
                const teacher = store.state.database.teachers.find((t) => t.schoolEmail == schoolEmail);
                if (teacher) {
                    const { schoolStaffId } = teacher;
                    const key = `staffPortfolioTotals_${schoolStaffId}`;
                    const cached = Boolean(store.state.database.cache.find((c) => c.key == key && (c.status == 'loading' || c.status == 'cached')));
                    if (!cached) {
                        populateTeacherTotals(store, teacher);
                    }
                }
            }
        },
    });
}

export function getPortfolioCacheKey($route, database) {
    const schoolEmail = $route.params?.schoolEmail || null;
    if (schoolEmail) {
        const teacher = database.teachers.find((t) => t.schoolEmail == schoolEmail);
        if (!teacher) return null;
        const { schoolStaffId } = teacher;
        return `staffProfileData_${schoolStaffId}`;
    }

    const studentEnrollmentId = $route.params?.studentEnrollmentId || null;
    if (studentEnrollmentId) {
        return `studentProfileData_${studentEnrollmentId}`;
    }
    return null;
}

export {
    portfolioMixins as default,
    populate,
};
