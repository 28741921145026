function GradeTemplateScale(props) {
    this.gradeTemplateId = props.gradeTemplateId;
    this.gradeTemplateScaleId = props.gradeTemplateScaleId;
    this.scaleId = props.scaleId;
    // this.markingPeriodValidMarkId = props.markingPeriodValidMarkId;
    this.minGrade = props.minGrade;
    this.maxGrade = props.maxGrade;
    this.mark = props.mark;
    this.numericPassthrough = props.numericPassthrough || null;
    this.color = props.color;
    this.displayName = props.displayName;
    this.nycPassing = props.passing;
}

export default GradeTemplateScale;
