import { getStudents, StudentFilter } from './studentMixins';

const courseWorkGradeMixins = {
    methods: {
        $_courseWorkGradeMixins_getCourseWorkGrades(course) {
            const { database } = this.$store.state;
            return getCourseWorkGrades(database, course);
        },
    },
};

function getCourseWorkGrades(database, course) {
    const { gradeTemplate, courseSectionIds } = course;
    const googleCourseId = course.googleCourseId || 0;
    const gradeTemplateId = gradeTemplate.gradeTemplateId || 0;
    if (!gradeTemplateId) {
        window.console.error('Could not find grade template');
        return [];
    }

    const students = getStudents(database, new StudentFilter({ course }));

    const courseWorkGrades = database.courseWorkGrades.filter((cwg) => courseSectionIds.includes(cwg.courseSectionId));

    let googleStudentSubmissions = [];
    if (googleCourseId !== null) {
        googleStudentSubmissions = database.googleStudentSubmissions
        .filter((cw) => googleCourseId && cw.googleCourseId == googleCourseId)
        .map((ss) => {
            //  filter against linked students
            const submission = ss;
            const { googleUserId } = ss;
            const googleStudent = students.find((s) => s.googleUserId == googleUserId);
            if (googleStudent) {
                submission.studentEnrollmentId = googleStudent.studentEnrollmentId;
            }
            return submission;
        })
        .filter((ss) => ss.studentEnrollmentId);
    }

    return [
        ...courseWorkGrades,
        ...googleStudentSubmissions,
    ];
}

function getGradeForStudent(database, courseWork, student) {
    const { studentEnrollmentId, googleUserId } = student;
    if (courseWork.courseWorkDomain == 'Local') {
        return database.courseWorkGrades.find((cwg) => cwg.courseWorkId == courseWork.courseWorkId && cwg.studentEnrollmentId == studentEnrollmentId) || null;
    }
    if (courseWork.courseWorkDomain == 'Google') {
        return database.googleStudentSubmissions.find((gss) => googleUserId && gss.courseWorkId == courseWork.googleCourseId && gss.googleUserId == googleUserId) || null;
    }
    return null;
}
export {
    courseWorkGradeMixins as default,
    courseWorkGradeMixins,
    getCourseWorkGrades,
    getGradeForStudent,
};
