function GradeTemplate(props) {
 this.gradeTemplateId = props.gradeTemplateId;
    this.gradeTemplateName = props.gradeTemplateName;
    this.gradeTemplateSchoolId = props.gradeTemplateSchoolId;
    this.gradeTemplateCourseSectionId = props.courseSectionId;
    this.averageMethod = props.averageMethod;
    this.mandatory = props.mandatory;
    this.excusedGrade = props.excusedGrade;
    this.gradeTemplateCreatedByUser = props.gradeTemplateCreatedByUser;
    this.gradeTemplateCreatedByUserId = props.gradeTemplateCreatedByUserId;
    this.gradeTemplateCreated = props.gradeTemplateCreated;
    this.gradeTemplateModified = props.gradeTemplateModified;
    this.categories = [];
    this.scales = [];
}

export default GradeTemplate;
