function MarkingPeriod(props, school) {
    this.schoolTermMarkingPeriodId = props.schoolTermMarkingPeriodId;
    this.markingPeriodName = props.markingPeriodName;
    this.markingPeriod = props.markingPeriod;
    this.cumulative = props.cumulative;
    this.schoolTermId = school.schoolTermId;

    const markingPeriodStart = props.markingPeriodStart.indexOf('T') > -1 ? props.markingPeriodStart : `${props.markingPeriodStart}T00:00:00.000Z`;
    const markingPeriodEnd = props.markingPeriodEnd.indexOf('T') > -1 ? props.markingPeriodEnd : `${props.markingPeriodEnd}T00:00:00.000Z`;

    this.start = markingPeriodStart;
    this.end = markingPeriodEnd;

    this.userMarkingPeriods = [];
    this.dates = {
        schoolStart: school.termStart,
        schoolEnd: school.termEnd,
        markingPeriodStart,
        markingPeriodEnd,
    };
    this.sumCategoryWorth = 0;
    this.sumCategoryEarned = 0;
    this.average = null;
    this.scaled = null;
    this.totals = {
        late: 0,
        excused: 0,
        missing: 0,
        pass: 0,
        fail: 0,
        all: 0,
    };
    this.categories = [];
}
export default MarkingPeriod;
