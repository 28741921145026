function GradeTemplateCategory(props) {
    this.gradeTemplateId = props.gradeTemplateId;
    this.gradeTemplateCategoryId = props.gradeTemplateCategoryId;
    this.gradeCategoryId = props.gradeCategoryId || 0;
    this.categoryName = props.categoryName;
    this.dropLowest = props.dropLowest;
    this.color = props.categoryColor || props.color;
    this.prorated = false;
    this.pointsEarned = 0;
    this.latePercentDock = props.latePercentDock || 0;
    this.pointsPossible = 0;
    this.percentEarned = 0;
    const { percentage } = props;
    this.categoryWorth = percentage ? percentage / 100 : 0;
    this.categoryEarned = 0;
    this.scaled = null;
    this.matchOn = props.matchOn || [];
    this.totals = {
        late: 0,
        excused: 0,
        missing: 0,
        pass: 0,
        fail: 0,
        all: 0,
    };
    this.assignments = [];
}
export default GradeTemplateCategory;
