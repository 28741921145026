function Assignment(props) {
    this.googleCourseWorkId = props.googleCourseWorkId;
    this.googleCourseId = props.googleCourseId;
    this.extCourseWorkId = props.extCourseWorkId;
    this.courseWorkTitle = props.courseWorkTitle;
    this.courseWorkType = props.courseWorkType;
    this.courseWorkState = props.courseWorkState;
    this.courseWorkDescription = props.courseWorkDescription;
    this.maxPoints = props.maxPoints || 0;
    this.assigneeMode = props.assigneeMode;
    this.creationTimeEpoch = props.creationTimeEpoch;
    this.dueDateEpoch = props.dueDateEpoch;
    this.dueDate = props.dueDate;
    this.creationTime = props.creationTime;
    this.submissionState = null;
    this.gradeTemplateCategoryId = props.gradeTemplateCategoryId || 0;
    this.gradeCategoryId = props.gradeCategoryId || 0;
    this.courseWorkWeight = props.courseWorkWeight || 1;
    this.schoolTermMarkingPeriodId = props.schoolTermMarkingPeriodId || 0;
    this.averageExclusiveForMp = props.averageExclusiveForMp || false;
    this.sortDate = props.dueDate || props.creationTime;
}
export default Assignment;
