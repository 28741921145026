function StudentGrade(props) {
    let google = null;
    let syncGrades = null;

    if (props.googleCourseWorkId) {
        this.courseSectionId = null;
        this.googleCourseWorkId = props.googleCourseWorkId;
        this.googleStudentSubmissionId = props.googleStudentSubmissionId;
        this.googleSubmissionId = props.googleSubmissionId;
        this.googleUserId = props.googleUserId;
        this.courseWorkType = props.courseWorkType;

        let mark = null;
        let type = 'missing';
        if (props.assignedGrade !== null) {
            type = 'assignedGrade';
            mark = props.assignedGrade;
        } else if (props.draftGrade !== null) {
            type = 'draftGrade';
            mark = props.draftGrade;
        }

        let percent = 0;
        if (props.maxPoints && mark) {
            percent = round(mark / props.maxPoints || 0, 4);
        }

        google = {
            pointTypeUsed: type,
            draftGrade: props.draftGrade,
            assignedGrade: props.assignedGrade,
            pointsPossible: props.maxPoints || 0,
            pointsEarned: mark || 0,
            percentEarned: percent,
            late: props.late || false,
            updated: props.updateTime,
            submissionState: props.submissionState || 'Missing',
        };

        // Default syncGrades for Google assignments before translation
        syncGrades = {
            pointsPossible: 0,
            pointsEarned: 0,
            percentEarned: 0,
            lateDeductionApplied: 0,
            scaled: {
                points: null,
                percent: null,
            },
        };
    }

    if (props.courseWorkId) {
        this.googleCourseWorkId = null;
        this.schoolTermId = props.schoolTermId;
        this.courseSectionId = props.courseSectionId;
        this.courseWorkId = props.courseWorkId;
        this.studentEnrollmentId = props.studentEnrollmentId;
        this.mark = props.mark;

        syncGrades = {
            averageMethod: 'Points',
            isLowest: false,
            isExcusedGrade: false,
            assignedGrade: props.mark,
            excludedFromAverage: false,
            numericPassthrough: null,
            lateDeductionApplied: 0,
            latePercentToDeduct: 0,
            pointsEarned: props.mark || 0,
            pointsPossible: props.maxPoints || 0,
            percentEarned: 0,
            scaled: {
                points: null,
                percent: null,
            },
        };
    }

    this.grade = { google, syncGrades };
}

function round(value, decimals) {
    // @ts-ignore
    return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
}

export default StudentGrade;
